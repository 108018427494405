import "../css/projectCard.css"


export default function ProjectCard(props){
    return(
        <div className="ProjectCard">
            <h3 className="CardName">{props.title}</h3>
            <img className="CarImage" src={props.image}/>
            <p className="CardDescription">{props.description}</p>
            {props.link && <a className="CardGithub" href={props.link} target="_blank">view in github</a>}
        </div>
    )

}