import "../css/about.css"
import MyImage from "../images/background-2.png"
import AboutIcon from "../images/about-icon.png"

export default function About(){
    return(
        <div className="about-items">
            <img  id="about" className="myImage" src = {MyImage}/>
            <div className="information">
                <div className="More-About">
                    <h2>More about me</h2>
                    <img src={AboutIcon} className="About-Icon"/>
                </div>
                
                <p>Hello! My name is Bishesh Pote. I graduated from the University of Texas at 
                    Arlington with my undergraduate degree in Software Engineering. 
                    I have worked on projects ranging from from developing web applications 
                    to building linux games. Every so often I find pleasure in designing 
                    graphics. Away from keyboard, I enjoy travelling, hiking, and 
                    following soccer.</p>
            </div>
        </div>
    )
}