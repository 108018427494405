import "../css/projectCard.css"
import ProjectCard from "./ProjectCard"
import ProjectData from "./ProjectData"
import ProjectHighligthsLogo from "../images/project_highlights.png"

export default function Projects(){

    const ProjectCards = ProjectData.map(item => {
        return(<ProjectCard 
                title = {item.title}
                description = {item.description}
                image = {item.image}
                link = {item.link}
               />)
    })
    return(
    
        <div id="project" className = "Project">
            <div className="Project-Header">
                <a className="Project-Highlight">Projects</a>
                <img src={ProjectHighligthsLogo} className="Project-Highlight-Logo" alt="logo" />
            </div>
            <div className = "Projects">
                {ProjectCards}
            </div>
        </div>
    )
}