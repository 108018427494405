import "../css/navbar.css"
import my_resume from '../files/my_resume.pdf'

export default function Navbar(){
    return(
        <nav className = "nav">
            <a className = "my-name" href="#"> Bishesh Pote</a>
            <ul className = "list-items">
                <a href="#about">About</a>
                {/* <a>Contact</a> */}
                <a href="#experience">Experience</a>
                <a href="#project">Projects</a>
                <a href={my_resume} target="_blank">Resume</a>
            </ul>
        </nav>
    )

}