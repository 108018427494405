import "../css/experience.css";
import Collapsible from 'react-collapsible';
import Wrench from '../images/wrench.png'



export default function Experience() {
    return (
    <div id = "experience" className="Experience"> 
      <div className="Experience-Header">
        <a className="Experience-Highlight">Work Experience</a> 
        <img src={Wrench} className="Wrench-Logo" alt="logo" />
        
      </div>    
      <div> 
      <Collapsible trigger="First Command Finnancial Services">
        <p>
        Associate Programmer
        </p>
        <p>
        June 2022 - Present
        </p>
        <ul>
        <li>
        Developed, deployed, and maintained Azure Function Apps focused on conducting comprehensive license verification for financial advisors, meeting strict FINRA regulations
        </li>
        <li>
        Enhanced paperless application platform for investments by implementing new features and addressing legacy bugs, optimizing overall functionality and user experience
        </li>
        <li>
        Implemented features in an advisor-facing Angular application, aimed at streamlining and automating manual tasks, resulting in efficient financial planning for clients
        </li>
        <li>
        Expanded the functionality of multi-layered APIs in .NET by implementing additional endpoints to accommodate new features for the advisor-facing application
        </li>
        <li>
        Developed and maintained automated test scripts for the advisor-facing application using SpecFlow, Selenium, and Gherkin language scenarios for behavior-driven development, leading to a significant reduction in manual testing efforts and faster release cycles
        </li>
        <li>
        Actively participated in deployment processes through Azure Pipelines and managed releases efficiently by incorporating Split.io for feature flagging and release management
        </li>

        </ul>

      </Collapsible>
      </div> 
      <div> 
      <Collapsible trigger="University Tutorial & Supplemental Instruction at UT Arlington">
        <p>
          Tutor
        </p>
        <p>
          August 2019 - May 2020
        </p>
        <ul>
          <li>
          Guided fellow students with problems related to algorithms, programming concepts, and mathematics using schematics of open-ended & redirecting questions
          </li>
        </ul>
      </Collapsible>
      </div> 

    </div>
    )
    

}
