export default [

    {
        id: 1,
        title: "Mavshell | C", 
        description: "A basic shell program that supports up to 10 command line parameters. It stores last 15 commands entered by the user which can be listed using the command 'history'.",
        image: "mavshell.png",
        link: "https://github.com/potebishesh/mavshell"
    }
    ,
    {
        id: 2,
        title: "UTAppointments | Node.js", 
        description: "Simple aesthetic, easy to follow, and easy setup for making office hours appointment for a Student Success Course at the University of Texas at Arlington.",
        image:"UTAppointment.png",
        link: "https://github.com/potebishesh/utappointments"
    }
    ,
    {
        id: 3,
        title: "Poker++ | C++", 
        description: "A 5-card draw poker developed in C++ using boost.asio and gtkmm libraries. This multiplayer game can be played across multiple systems connected locally.",
        image: "Poker++.png",
        link: "https://github.com/potebishesh/poker"
    },
    {
        id: 4,
        title: "SplitApp | C++", 
        description: "Split app simplifies splitting bills by creating a group of members, adding expenses to split it among members, and tracking expenses of each member.",
        image: "SplitApp.jpg",
    },
]