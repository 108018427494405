import "../css/introduction.css";
import waving_hand from '../images/waving_hand.png';
import my_image from '../images/background.png'
import my_imagetwo from '../images/football-player.png'
import my_resume from '../files/my_resume.pdf'
import Collapsible from 'react-collapsible';



export default function Introduction() {
    return (
        <div className="Intro-items">        
            <div className="App">
            <header className="App-header">
                <a className="Introduction">
                    Hey there!
                </a>
                <img src={waving_hand} className="waving_hand" alt="logo" />
            </header>
            <a className="Name">
                I'm Bishesh, a software developer in Dallas
            </a>
            <a className="AboutMe" href={my_resume} target="_blank">
                check my resume &#8594;
            </a>

            </div>
            <div className="flip-card">
            <div className="flip-card-inner">
            <div className="flip-card-front">
                <img className="myImage" src={my_image} alt="Avatar" />
            </div>
            <div className="flip-card-back">
                <img className="myImage" src={my_imagetwo} alt="Avatar" />
            </div>
            </div>
            </div>
       

</div>
    )
    

}
